import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import styles from '../styles/DocumentCitationData.styles';
import { getDocumentFlag } from '../utils/documentResultsUtils';
import DocumentsPDFMetadata from './DocumentsPDFMetadata';

const DocumentCitationData = ({ resultDetails }: any) => {
  const title = resultDetails?.documentTitle || '';
  const subtitle = resultDetails?.subtitle ? ` (${resultDetails?.subtitle})` : '';
  const metadataList = resultDetails?.metadataList || [];
  const FlagIcon = getDocumentFlag(resultDetails?.dataSource);
  return (
    <Stack sx={styles.container}>
      <Box sx={styles.headingContainer}>
        {FlagIcon ? <FlagIcon sx={{ fontSize: 18 }} /> : null}
        {resultDetails?.applicationType && (
          <Tooltip title={resultDetails?.applicationType?.tooltipTitle}>
            <Chip
              size='small'
              label={resultDetails?.applicationType?.label}
              key={resultDetails?.applicationType?.label}
              sx={{
                ...styles.labelChip,
                bgcolor: resultDetails?.applicationType?.color
              }}
            />
          </Tooltip>
        )}
        {metadataList
          ?.filter((item: { type: string }) => item?.type === 'identifer')
          ?.map((tags: { id: string; value: string; label: string }) => (
            <Tooltip key={tags?.id} title={`${tags?.label}: ${tags?.value}`}>
              <Typography noWrap variant='body1' key={tags?.id} sx={styles.tagText}>
                {tags?.value}
              </Typography>
            </Tooltip>
          ))}
      </Box>
      <Box sx={styles.metadataConatiner}>
        <DocumentsPDFMetadata
          title={`${title}${subtitle}`}
          metadataList={metadataList?.filter(
            (item: { type: string }) => item?.type !== 'identifer'
          )}
          showChatRia={false}
        />
      </Box>
      <Box sx={styles.textConatiner}>
        <Tooltip title={resultDetails?.paragraphText}>
          <Typography
            variant='subtitle2'
            maxHeight={110}
            sx={styles.description}
            align='justify'
            overflow='hidden'
            textOverflow='ellipsis'
            dangerouslySetInnerHTML={{ __html: resultDetails?.paragraphText }}
          />
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default React.memo(DocumentCitationData);
