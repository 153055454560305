/* eslint-disable react/require-default-props */
import React, { memo, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import ResultsStore from '../../../store/SearchResults';
import ResultsAction from '../../../store/SearchResults/actions';

import { CloseIcon, ThumbsDownIcon, ThumbsUpIcon, UpArrowIcon } from '../../../assets/svgs/Icons';
import PDFViewer from '../../../components/PDFViewer';

import useDocumentsViewFunctions from '../hooks/useDocumentsViewFunctions';

import styles from '../styles/PdfDetailsView.styles';
import LanguageToggleButton from '../../../components/LanguageToggleComponents/LanguageToggleButton';
import LanguageToggleText from '../../../components/LanguageToggleComponents/LanguageToggleText';
import DocumentsPDFMetadata from './DocumentsPDFMetadata';
import { getDocumentFlag } from '../utils/documentResultsUtils';

const useWindowSize = (documentLink: string) => {
  const [size, setSize] = useState({ offsetHeightOfViewer: 180, widthOfPDFViewer: 670 });

  useLayoutEffect(() => {
    const updateSize = () => {
      const containerDiv = document.getElementById('pdf-viewer-container');
      if (containerDiv) {
        setSize({
          offsetHeightOfViewer: containerDiv.getBoundingClientRect().top,
          widthOfPDFViewer: containerDiv.getBoundingClientRect().width
        });
      }
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [documentLink]);

  return size;
};

const PdfDetailsView = ({ fromUserProfile = false }: { fromUserProfile?: boolean }) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [isPdf, setIsPdf] = useState(false);
  const [documentLink, setDocumentLink] = useState('');
  const [phraseToHighlight, setPhraseToHighlight] = useState('');
  const [keywordsToHighlight, setKeywordsToHighlight] = useState([]);

  const { offsetHeightOfViewer, widthOfPDFViewer } = useWindowSize(documentLink);
  const pdfViewerRef = useRef<HTMLDivElement>(null);

  const { handleResultClick, handleChatRia, documentResultFeedback } = useDocumentsViewFunctions();

  const toggleTextLanguage = (value: boolean) => {
    resultsDispatch({ type: ResultsAction.SET_ORIGINAL_TEXT_ENABLED, value });
  };

  useEffect(() => {
    if (documentLink && pdfViewerRef.current) {
      const width = `${widthOfPDFViewer - 25}px`;
      pdfViewerRef.current.style.width = width;
      pdfViewerRef.current.style.minWidth = width;
      pdfViewerRef.current.style.maxWidth = width;
      pdfViewerRef.current.style.top = `${offsetHeightOfViewer}px`;
    }
  }, [widthOfPDFViewer, offsetHeightOfViewer, documentLink]);

  useEffect(() => {
    if (resultsState?.selectedDocument) {
      setDocumentLink(
        resultsState.originalTextEnabled
          ? resultsState.selectedDocument?.pdfUrlOriginal ?? resultsState.selectedDocument.pdfUrl
          : resultsState.selectedDocument.pdfUrl
      );
      setPhraseToHighlight(resultsState.selectedDocument.phraseToHighlight);
      setKeywordsToHighlight(resultsState.selectedDocument.keywordsToHighlight);
    }
  }, [resultsState.originalTextEnabled, resultsState?.selectedDocument]);

  useEffect(() => {
    setIsPdf(
      documentLink ? documentLink.includes('.pdf?') || documentLink.startsWith('blob:') : false
    );
  }, [documentLink]);

  if (resultsState && !resultsState.selectedDocument) {
    return null;
  }
  const resultDetails = resultsState?.selectedDocument?.resultDetails || {};
  const title = resultDetails?.documentTitle || '';
  const subtitle = resultDetails?.subtitle ? ` (${resultDetails?.subtitle})` : '';
  const metadataList = resultDetails?.metadataList || [];
  const FlagIcon = getDocumentFlag(resultDetails?.dataSource);

  const handleChatRIAClick = () => {
    if (documentLink) {
      handleChatRia({
        resultDetails,
        pdfUrl: documentLink
      });
    }
  };

  return (
    <Stack
      spacing={1}
      ref={pdfViewerRef}
      sx={fromUserProfile ? styles.pdfViewerContainerUserProfile : styles.pdfViewerContainer}>
      {/* selected row title */}
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center' sx={{ maxWidth: '70%' }}>
          {Object.keys(resultDetails)?.length !== 0 && (
            <DocumentsPDFMetadata
              title={`${title}${subtitle}`}
              metadataList={metadataList}
              showChatRia
              isPdf={isPdf}
              handleChatRIAClick={handleChatRIAClick}
              flag={FlagIcon ? <FlagIcon sx={{ fontSize: 18 }} /> : null}
            />
          )}
        </Stack>
        <Stack direction='row' spacing={1} alignItems='center'>
          <IconButton target='_blank' href={documentLink}>
            <Tooltip title='Open in new tab' placement='bottom-end' sx={styles.icon}>
              <UpArrowIcon sx={{ fontSize: '18px' }} />
            </Tooltip>
          </IconButton>
          {resultsState.selectedDocument?.pdfUrlOriginal && (
            <LanguageToggleButton
              source={resultsState.selectedDocument?.resultDetails?.dataSource}
              originalTextEnabled={resultsState.originalTextEnabled}
              toggleTextLanugage={toggleTextLanguage}
              color='green'
            />
          )}
          <Tooltip title='Close' placement='bottom-end'>
            <IconButton
              onClick={() =>
                handleResultClick({
                  onClose: true,
                  resultId: resultsState.selectedDocument.resultId
                })
              }>
              <CloseIcon sx={{ fontSize: 14, color: 'gray.mediumDark' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      {/* pdf document */}
      <Stack sx={styles.pdfViewer} spacing={0.5}>
        {resultsState.originalTextEnabled && <LanguageToggleText styles={{ textAlign: 'start' }} />}
        {isPdf ? (
          <PDFViewer
            fileUrl={documentLink}
            // @ts-ignore
            initialPage={documentLink.split('#page=')[1] - 1 ?? 0}
            defaultScale='PageWidth'
            phraseToHighlight={phraseToHighlight}
            keywordsToHighlight={keywordsToHighlight}
          />
        ) : (
          <iframe src={documentLink} title='pdf' style={{ width: '100%', height: '90vh' }} />
        )}
      </Stack>

      {/* feedback row */}
      <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          justifyContent='center'
          sx={styles.feedbackContainer}>
          <Typography variant='body1' sx={styles.feedbackText}>
            Help us create better experience for you. Was the result helpful?
          </Typography>
          <IconButton size='small' onClick={() => documentResultFeedback('RELEVANT')}>
            <Tooltip title='Like' placement='bottom-end'>
              <ThumbsUpIcon sx={styles.icon} />
            </Tooltip>
          </IconButton>
          <IconButton size='small' onClick={() => documentResultFeedback('NOT_RELEVANT')}>
            <Tooltip title='Dislike' placement='bottom-end'>
              <ThumbsDownIcon sx={styles.icon} />
            </Tooltip>
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(PdfDetailsView);
