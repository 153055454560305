import React, { useContext, useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import ResultContent from './ResultContent';
import ResultsStore from '../../../../store/SearchResults';
import PdfDetailsView from '../PdfDetailsView';
import Loading from '../Loading';

const RiaInsights = ({
  renderedInPopup,
  pageReferences,
  insights,
  addReport,
  addFeedback,
  loading
}: any) => {
  const { resultsState } = useContext(ResultsStore);

  // Function to replace keys with buttons
  // const replaceKeysWithButtons = (text: any) => {
  //   const pageNumberArr = [];
  //   // eslint-disable-next-line no-restricted-syntax
  //   for (const key in pageReferences) {
  //     const buttonNumber = pageReferences[key];
  //     const button = `<span  id="${buttonNumber}" title="Scroll to this page" style="border-radius:50px;font-size:14px;padding-left:6px;padding-right:6px;border:1px solid #3ec886;color:#3ec886;cursor:pointer;">P${buttonNumber}</span>`;

  //     if (text.includes(key)) {
  //       text = text.replaceAll(`${key}`, button);
  //       pageNumberArr.push(buttonNumber);
  //     }
  //   }
  //   const currentCitation = citation;
  //   currentCitation[chatBubbleId] = pageNumberArr;
  //   setCitations(currentCitation);

  //   return text;
  // };

  return (
    <Grid
      maxWidth='lg-md'
      m='auto'
      id={renderedInPopup ? 'scroll-container-drawer' : 'scroll-container'}
      container
      mt={7}
      sx={
        renderedInPopup
          ? {
              height: '80vh',
              overflowY: 'auto'
            }
          : {
              mb: 10
            }
      }>
      <Grid
        item
        xs={resultsState?.selectedDocument ? 6 : 12}
        sx={{ bgcolor: 'white.main', m: 'auto' }}
        maxWidth={1280}>
        <ResultContent content={insights?.html} citations={insights?.citations} loading={loading} />
      </Grid>

      {resultsState?.selectedDocument && (
        <Grid item id='pdf-viewer-container' xs={6}>
          <Stack
            sx={{
              position: 'fixed',
              top: '188px',
              overflowY: 'auto',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'column'
            }}
            height='80vh'>
            {resultsState.documentLoader ? (
              <Stack justifyContent='center' alignItems='center'>
                <Loading showTips={false} />
              </Stack>
            ) : (
              <Stack>
                <PdfDetailsView />
              </Stack>
            )}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(RiaInsights);
