import React from 'react';

import { Stack, Tooltip, Typography } from '@mui/material';

import styles from '../styles/DocumentTitle.styles';
import InfoIcon from '../../../assets/svgs/CardsPage/InfoIcon';

const DocumentsViewTitle = ({ sourceName, totalCount, overallCount }: any) => {
  return (
    <Stack>
      <Typography variant='body1' sx={styles.titleText}>
        Showing
        <Typography component='span' fontWeight='bold'>
          Top {totalCount} results
        </Typography>
        <Tooltip
          title={`Showing ${totalCount} of ${overallCount} matches from ${sourceName} documents`}>
          <InfoIcon sx={styles.infoIcon} />
        </Tooltip>
      </Typography>
    </Stack>
  );
};

export default React.memo(DocumentsViewTitle);
