/* eslint-disable no-console */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { isEmpty } from 'lodash';
import orderBy from 'lodash/orderBy';
import { toast } from 'react-toastify';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import { submitAriaFeedback } from '../../../api/pages/Aria';
import { DOCUMENT_SEARCH_SUBTEXT, DOCUMENT_VIEW_RESULTS_PER_PAGE } from '../constants';
import {
  formatDocumentResults,
  getDocumentSourceName,
  prepareDocumentCard
} from '../utils/documentResultsUtils';
import { SourceDropdown } from '../../Home/types';
import {
  createSourceModuleDropdownMapping,
  createSourceModulePayloadMapping,
  getDefaultHomePageSourceDropDown
} from '../../Home/utils';
import { decodeBase64ToObject, encodeObjectToBase64 } from '../../../utils/encodeDecodeObject';

import { Result, SortType } from '../types/documentResultsUtils.types';
import RESULT_VIEW_TYPES from '../components/constants';
import { getGenericSearch } from '../../../api/pages/ResultsPage';
import { prepareAPIPayload } from '../utils/searchUtils';
import AuthContext from '../../../store/Auth/AuthProvider';

const useDocumentsViewFunctions = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(GlobalStore) as any;
  const { payload }: any = useParams();
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchedCount, setFetchedCount] = useState(0);
  const [sourceName, setSourceName] = useState('Multiple source');
  const [selectedSort, setSelectedSort] = useState<SortType>({
    id: 'Relevance: Very High -> Low',
    value: 'default'
  });
  const [paginatedResults, setPaginatedResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  // state for explore more selected filters
  const [exploreSelectedFilters, setExploreSelectedFilters] = useState({
    selectedFilters: {},
    selectedFiltersOrder: []
  });
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSources, setSelectedSources] = useState<SourceDropdown[]>([]);
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    dispatch({
      type: GlobalActions.TRIGGER_CHATRIA_CLOSE_SESSION,
      value: !state.chatRiaCloseSessionTrigger
    });
    // closing Chat RIA initial sessions if any on mount.
  }, []);

  useEffect(() => {
    if (payload) {
      const searchPayload: any = decodeBase64ToObject(payload);
      const convertedSelectedSources = createSourceModuleDropdownMapping(
        searchPayload?.source ?? {}
      );
      setSelectedSources(convertedSelectedSources);
    } else {
      setSelectedSources(getDefaultHomePageSourceDropDown());
    }
  }, [payload]);

  /**
   * Handles the click event on a document result by dispatching an action to update the selected document in the state.
   * This function is typically used to manage the state of a document viewer when a user interacts with a document result.
   * It also processes the document to highlight specific text if needed.
   *
   * @param {Object} params - The parameters for handling the result click.
   * @param {string} [params.resultId=''] - The unique identifier for the clicked result.
   * @param {Object} [params.resultDetails={}] - The detailed information about the result.
   * @param {string} [params.pdfUrl=''] - The URL to the PDF document, if applicable.
   * @param {boolean} [params.onClose=false] - A flag indicating whether the action is a close event.
   *
   * Upon clicking a result, the function extracts and processes the text and highlighted words from resultDetails.
   * When a result is clicked, the function dispatches an action to update the application state with the selected document's details.
   * If the onClose flag is true, it indicates the click event is intended to close or deselect the current document, setting the selected document in the state to null.
   * Otherwise, it updates the state with the provided resultId, resultDetails, and pdfUrl.
   *
   * @example
   * Example usage in a component
   * handleResultClick({
   *   resultId: '123',
   *   resultDetails: { title: 'Document Title', date: '2023-01-01',item: { text: 'Sample Text', highlighted_words: ['sample', 'text'] }  },
   *   pdfUrl: 'http://example.com/document.pdf'
   * });
   */
  const handleResultClick = async ({
    resultId = '',
    resultDetails = {},
    pdfUrl = '',
    pdfUrlOriginal = '',
    onClose = false,
    triggerAriaResponse = false
  }: {
    resultId?: string;
    resultDetails?: any;
    pdfUrl?: string;
    pdfUrlOriginal?: string;
    onClose?: boolean;
    triggerAriaResponse?: boolean;
  }) => {
    // If the click was for close, set the selected documents to null to close the pdf viewer
    if (onClose) {
      resultsDispatch({
        type: ResultActions.SET_SELECTED_DOCUMENT,
        value: null
      });
      resultsDispatch({
        type: ResultActions.SET_ORIGINAL_TEXT_ENABLED,
        value: false
      });
      resultsDispatch({
        type: ResultActions.SET_SCROLL_RESULT_ID,
        value: {
          resultId,
          onClose: true
        }
      });
      // If the pdf was opened through verify btn from top 10 summary,
      // then show the top 10 summary again when  pdf is closed
      if (resultsState.riaResponseTitle === 'Key Insights') {
        resultsDispatch({
          type: ResultActions.SET_SHOW_TOP_10_SUMMARY,
          value: true
        });
      }
      return;
    }

    if (!resultsState.resultsIdsInView?.includes(resultId) && state.module === 'documents') {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: {
          message:
            "Please clear explore filters or disable 'Show Unique Results' to view the desired result.",
          status: true,
          color: 'info'
        }
      });
      return;
    }

    resultsDispatch({ type: ResultActions.SET_DOCUMENT_LOADER, value: true });
    resultsDispatch({
      type: ResultActions.SET_SHOW_TOP_10_SUMMARY,
      value: false
    });
    try {
      if (resultDetails.item.document_url.includes('.txt')) {
        resultsDispatch({
          type: ResultActions.SET_SELECTED_DOCUMENT,
          value: {
            resultId,
            resultDetails,
            pdfUrl: `${resultDetails.item.document_url}`,
            pdfUrlOriginal
          }
        });
        return;
      }
      if (triggerAriaResponse) {
        resultsDispatch({
          type: ResultActions.SET_SELECTED_ARIA_RESPONSE,
          value: resultDetails
        });
        resultsDispatch({
          type: ResultActions.SET_RIA_RESPONSE_TITLE,
          value: 'Selected Result'
        });
      }
      if (resultsState.selectedDocument?.resultId === resultId) {
        return;
      }
      const phraseToHighlight = resultDetails.item.text
        .replaceAll('<b>', '')
        .replaceAll('</b>', '');
      const keywordsToHighlight = resultDetails.item.highlighted_words;
      resultsDispatch({
        type: ResultActions.SET_SELECTED_DOCUMENT,
        value: {
          resultId,
          resultDetails,
          pdfUrl,
          pdfUrlOriginal,
          keywordsToHighlight,
          phraseToHighlight
        }
      });
      resultsDispatch({
        type: ResultActions.SET_SCROLL_RESULT_ID,
        value: {
          resultId,
          onClose: false
        }
      });
    } catch (err) {
      console.error(err);
    } finally {
      resultsDispatch({ type: ResultActions.SET_DOCUMENT_LOADER, value: false });
    }
  };

  const handleAriaResponseClick = (selectedResult: any) => {
    resultsDispatch({
      type: ResultActions.SET_SHOW_TOP_10_SUMMARY,
      value: false
    });
    resultsDispatch({
      type: ResultActions.SET_RIA_RESPONSE_TITLE,
      value: 'Selected Result'
    });
    resultsDispatch({
      type: ResultActions.SET_SELECTED_ARIA_RESPONSE,
      value: selectedResult
    });
  };

  /**
   * Determines the source name and total count of document results from the given state.
   * This function updates the state with the name of the source of the documents and the total count of results.
   *
   * - If there's exactly one source, the source name is set to that specific source.
   * - If there are multiple sources, the source name is set to 'Multiple source'.
   * - The total count of documents is also set, defaulting to 0 if undefined.
   */
  const getDocumentResultTitle = async () => {
    const { decryptedSource } = resultsState;
    const sourceKeys = Object.keys(decryptedSource);

    if (sourceKeys.length > 1) {
      resultsDispatch({
        type: ResultActions.SET_EXPLORE_PAGE_MAPPER,
        value: { source: '', commonMapper: true }
      });
      setSourceName(DOCUMENT_SEARCH_SUBTEXT['Multiple source']);
    } else {
      let mappedSourceName = sourceKeys[0] ?? state.module;
      if (
        mappedSourceName === 'eu' ||
        mappedSourceName === 'us' ||
        mappedSourceName === 'ca' ||
        mappedSourceName === 'uk'
      ) {
        const subsetList = decryptedSource[mappedSourceName];
        if (subsetList.length === 1) {
          [mappedSourceName] = subsetList;
          // TODO: This is a temporary fix for the source name mapping.
          // This should be removed in the code cleanup follow up
          if (mappedSourceName === 'sba') {
            mappedSourceName = 'us';
          }
          if (mappedSourceName === 'wr') {
            mappedSourceName = 'fda-written-request';
          }
          if (sourceKeys[0] === 'eu' && mappedSourceName === 'guidance') {
            mappedSourceName = 'ema-guidance';
          }
          if (sourceKeys[0] === 'ca' && mappedSourceName === 'guidance') {
            mappedSourceName = 'canada-guidance';
          }
          if (sourceKeys[0] === 'uk') {
            mappedSourceName = mappedSourceName === 'mhra' ? 'mhra' : 'uk';
          }
        } else {
          resultsDispatch({
            type: ResultActions.SET_EXPLORE_PAGE_MAPPER,
            value: { source: '', commonMapper: true }
          });
          setSourceName(DOCUMENT_SEARCH_SUBTEXT['Multiple source']);
          return;
        }
      }
      resultsDispatch({
        type: ResultActions.SET_EXPLORE_PAGE_MAPPER,
        value: { source: mappedSourceName, commonMapper: false }
      });

      setSourceName(DOCUMENT_SEARCH_SUBTEXT[mappedSourceName]);
    }
    // Defaulting to 0 if undefined
  };

  const handleShareClick = async (rowDetails: any) => {
    await dispatch({
      type: GlobalActions.SET_SHARE_LINK,
      value: {
        entityType: 'aria_result_link', // eslint-disable-next-line no-underscore-dangle
        entityDetails: {
          title: rowDetails?.resultDetails?.category,
          text: rowDetails?.resultDetails?.paragraphText,
          s3ResourceLink: rowDetails?.resultDetails?.s3Link
        },
        open: true,
        ariaResultId: rowDetails?.resultId
      }
    });
  };

  /**
   * Handles sorting of search results based on a specified sort type.
   * @param {SortType} sortType - Object containing the sort type details.
   */
  const handleSortBy = async (sortType: SortType): Promise<void> => {
    // Update the selected sort type and document results
    setSelectedSort(sortType);
    resultsDispatch({ type: ResultActions.SET_SELECTED_DOCUMENT, value: null });
  };

  const handleChatRia = ({
    resultDetails = {},
    pdfUrl = ''
  }: {
    resultDetails?: any;
    pdfUrl?: string;
  }) => {
    const mappedSourceName = getDocumentSourceName(resultDetails.dataSource);

    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });

    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        ...resultDetails,
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
  };

  const handleFavoriteClick = async ({
    identifier,
    isFavorite
  }: {
    // result ID of the result
    identifier: string;
    isFavorite: boolean;
  }) => {
    const { documentResults } = resultsState;
    const currentRes = documentResults;
    const index = currentRes?.results?.findIndex((x: any) => x?.result_id === identifier) ?? -1;
    if (index === -1) return;
    currentRes.results[index].isFavorite = isFavorite;
    resultsDispatch({ type: ResultActions.SET_DOCUMENT_RESULTS, value: currentRes });
  };

  const handleProjectClick = async ({
    identifier,
    project
  }: {
    // result ID of the result
    identifier: string;
    project: any;
  }) => {
    const { documentResults } = resultsState;
    const currentRes = documentResults;
    const index = currentRes?.results?.findIndex((x: any) => x.result_id === identifier) ?? -1;
    if (index === -1) return;
    if (project?.inProject) {
      currentRes.results[index].inProjects = currentRes.results[index].inProjects.filter(
        (p: any) => (p?.project_id ?? p?.id) !== (project?.project_id ?? project?.id)
      );
    } else {
      currentRes.results[index].inProjects = [
        ...currentRes.results[index].inProjects,
        { id: project?.project_id ?? project?.id, name: project?.name }
      ];
    }
    resultsDispatch({ type: ResultActions.SET_DOCUMENT_RESULTS, value: currentRes });
  };

  /**
   * Toggles the display of unique document results.
   * It filters the results to exclude items marked as duplicates and dispatches an action to update the state.
   * This updated state includes the flag to show or hide unique results and the count of unique results.
   */
  const handleUniqueResultsToggle = async () => {
    resultsDispatch({
      type: ResultActions.SET_SHOW_UNIQUE_RESULTS,
      value: {
        enabled: !resultsState.showUniqueResults.enabled,
        resultCount: null
      }
    });
  };

  /**
   * Updates the state with the selected sources.
   * @param {SourceDropdown[]} values - The selected items from the source dropdown.
   */
  const handleSourceChange = (values: SourceDropdown[]) => {
    setSelectedSources(values);
  };

  /**
   * Makes a search based on the selected sources.
   * This function performs the following actions:
   * - Closes the dropdown menu.
   * - Converts the selected sources for the search payload.
   * - Encodes the updated payload and updates the URL.
   * - Dispatches an action to reset or update document results.
   * - Initiates fetching of new document results based on the updated payload.
   *
   */
  const makeSearch = useCallback(
    (sources?: SourceDropdown[]) => {
      setSourceDropDownAnchorEl(null);
      // this check is necessary as if no filter is present, and we are setting isSourceChangedWithFilters true by changing the source
      // then useEffect in useFetchResults custom hook won't be executed
      if (Object.keys(resultsState?.filters)?.length > 0) {
        resultsDispatch({ type: ResultActions.SET_FILTERS, value: {} });
        resultsDispatch({ type: ResultActions.SET_IS_SOURCE_CHANGED_WITH_FILTERS, value: true });
      }
      const finalSelectedSources = sources ?? selectedSources;
      const convertedSelectedSources = createSourceModulePayloadMapping(finalSelectedSources);
      const searchPayload: any = decodeBase64ToObject(payload);
      const newPayload = {
        ...searchPayload,
        source: convertedSelectedSources,
        // need to reset the search ids as when source is changed the search should be happened again
        application_search_id: '',
        ct_search_id: ''
      };
      // need to reset the ct results as when source is changed the older data should be reset as we are checking the row id from the results
      resultsDispatch({ type: ResultActions.SET_CT_RESULTS, value: [] });
      const encodedPayload = encodeObjectToBase64(newPayload) ?? '';
      resultsDispatch({
        type: ResultActions.SET_DOCUMENT_RESULTS,
        value: {}
      });
      resultsDispatch({ type: ResultActions.SET_SELECTED_DOCUMENT, value: null });
      history.push(`/search/${encodedPayload}`);
    },
    [selectedSources, payload]
  );

  /**
   * Submits user feedback for a document result and updates the global state to show a toast message.
   * @param {string} feedbackType - User's reaction to the document result (e.g., 'NOT_RELEVANT', 'RELEVANT').
   */
  const documentResultFeedback = async (feedbackType: string) => {
    (await submitAriaFeedback('us' as string, {
      query_id: resultsState.selectedDocument.resultDetails.queryId,
      result_id: resultsState.selectedDocument.resultId,
      user_reaction: feedbackType
    })) as any;

    dispatch({
      type: GlobalActions.SET_ALERT,
      value: { message: 'Thank you for your feedback!', status: true, color: 'success' }
    });
  };

  const handleDidYouMeanTextClick = () => {
    const didYouMeantext = resultsState.documentResults.did_you_mean;
    const search = didYouMeantext?.replace(/<\/?[^>]+(>|$)/g, ''); // Remove html tags
    resultsDispatch({ type: ResultActions.SET_IS_CONTENT_LOADING, value: true });

    const decodedPayload: any = decodeBase64ToObject(payload);
    decodedPayload.search_term = search;
    decodedPayload.view_type = RESULT_VIEW_TYPES.DOCUMENT;
    history.push(`/search/${encodeObjectToBase64(decodedPayload)}`);
  };

  const findDocumentByResultId = (resultId: string) => {
    const hoverResult = resultsState.documentResults.results.find(
      (resulItem: any) => resulItem.result_id === resultId
    );

    return prepareDocumentCard(hoverResult);
  };

  const getCurrentPage = () => {
    const resultsPerPage = DOCUMENT_VIEW_RESULTS_PER_PAGE;
    const currentResults = paginatedResults.length;
    return Math.floor(currentResults / resultsPerPage);
  };

  const getAPIPayload = () => {
    const searchPayload: any = decodeBase64ToObject(payload);
    return prepareAPIPayload(searchPayload, RESULT_VIEW_TYPES.DOCUMENT, resultsState, currentUser);
  };

  const loadMore = useCallback(async () => {
    try {
      setLoading(true);
      if (totalCount === 0 || fetchedCount === 0 || fetchedCount >= totalCount) {
        return;
      }

      // Get current page number , Make API call to get of next page and add it to the current state
      const currentPage = getCurrentPage();
      const nextPage = currentPage + 1;

      // Make API call to get of next page and add it to the current state
      const apiPayload = resultsState.isAriaOpened
        ? resultsState?.decryptedPayload
        : getAPIPayload();

      const res: any = await getGenericSearch(apiPayload, nextPage);
      if (res?.status === 200 || res?.status === 204) {
        const result = res?.data?.body?.result || {};
        const formattedResults = formatDocumentResults(result);
        resultsDispatch({
          type: ResultActions.SET_DOCUMENT_RESULTS,
          value: {
            ...resultsState.documentResults,
            results: [...resultsState.documentResults.results, ...formattedResults.results]
          }
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [fetchedCount, totalCount, resultsState?.decryptedPayload]);

  const filterResultsBasedOnExplore = (results: any, selectedFiltersInfo: any) => {
    // Handle Tab switch
    if (!results) return [];
    let newResults = [...results];
    selectedFiltersInfo?.selectedFiltersOrder?.forEach((category: any) => {
      newResults = newResults?.filter(result =>
        selectedFiltersInfo?.selectedFilters[category]?.some((option: any) =>
          Array.isArray(result[category])
            ? result[category].includes(option)
            : result[category] === option
        )
      );
    });
    return newResults;
  };
  const filteredMemoResults = (documentResults: any, isUnique: boolean) => {
    const results = isEmpty(exploreSelectedFilters)
      ? documentResults
      : filterResultsBasedOnExplore(documentResults, exploreSelectedFilters);

    const uniqueResults = isUnique
      ? results.filter((result: any) => !result.is_duplicate)
      : results;

    // Assign 'answer' score to elements of type 'answer'
    const updatedResults =
      uniqueResults?.map((element: Result) => ({
        ...element,
        score_confidence: element.type === 'answer' ? 'answer' : element.score_confidence
      })) || [];

    // Define custom order for sorting
    const sortOrder = ['answer', 'VERY_HIGH', 'HIGH', 'MEDIUM', 'LOW'];
    const sortedResults =
      selectedSort.value === 'default'
        ? orderBy(updatedResults, [result => sortOrder.indexOf(result.score_confidence)], ['asc'])
        : orderBy(updatedResults, [selectedSort.value], [selectedSort.sortType || 'asc']);

    resultsDispatch({
      type: ResultActions.SET_EXPLORE_PAGE_RESULTS_COUNT,
      value: results?.length ?? 0
    });
    // Extracting result_ids currently in active view into a list
    const resultIds = results?.map((res: any) => res.result_id) ?? [];
    resultsDispatch({ type: ResultActions.SET_RESULT_IDS_IN_VIEW, value: resultIds });
    let alertMessage = '';
    if (resultsState.showUniqueResults.enabled) {
      if (isEmpty(exploreSelectedFilters.selectedFiltersOrder)) {
        if (selectedSort.id === 'Relevance: Very High -> Low') {
          alertMessage = `Showing unique results.`;
        } else {
          alertMessage = `Showing results on selected filters`;
        }
      } else if (selectedSort.id === 'Relevance: Very High -> Low') {
        alertMessage = `Showing results on selected filters`;
      } else {
        alertMessage = `Showing results on selected filters`;
      }
    } else if (isEmpty(exploreSelectedFilters.selectedFiltersOrder)) {
      if (selectedSort.id !== 'Relevance: Very High -> Low') {
        alertMessage = `Showing results sorted by ${selectedSort.id}.`;
      }
    } else if (selectedSort.id === 'Relevance: Very High -> Low') {
      alertMessage = `Showing results on selected filters`;
    } else {
      alertMessage = `Showing results on selected filters`;
    }
    if (alertMessage) {
      toast.success(alertMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        icon: true,
        hideProgressBar: false
      });
    }
    return sortedResults;
  };

  const handleInsightCitationClick = (resultId: string, resultDetails: any) => {
    if (resultDetails.item.document_url.includes('.txt')) {
      resultsDispatch({
        type: ResultActions.SET_SELECTED_DOCUMENT,
        value: {
          resultId,
          resultDetails,
          pdfUrl: `${resultDetails.item.document_url}`,
          pdfUrlOriginal: resultDetails.pdfUrlOriginal
        }
      });
      return;
    }
    if (resultsState.selectedDocument?.resultId === resultId) {
      return;
    }
    const phraseToHighlight = resultDetails.item.text.replaceAll('<b>', '').replaceAll('</b>', '');
    const keywordsToHighlight = resultDetails.item.highlighted_words;
    resultsDispatch({
      type: ResultActions.SET_SELECTED_DOCUMENT,
      value: {
        resultId,
        resultDetails,
        pdfUrl: resultDetails.pdfUrl,
        pdfUrlOriginal: resultDetails.pdfUrlOriginal,
        keywordsToHighlight,
        phraseToHighlight
      }
    });
  };

  return {
    handleResultClick,
    getDocumentResultTitle,
    sourceName,
    exploreSelectedFilters,
    setExploreSelectedFilters,
    handleShareClick,
    handleSortBy,
    selectedSort,
    handleChatRia,
    handleFavoriteClick,
    handleProjectClick,
    handleUniqueResultsToggle,
    handleSourceChange,
    makeSearch,
    selectedSources,
    sourceDropDownAnchorEl,
    setSourceDropDownAnchorEl,
    documentResultFeedback,
    handleDidYouMeanTextClick,
    handleAriaResponseClick,
    findDocumentByResultId,
    paginatedResults,
    loadMore,
    loading,
    totalCount,
    fetchedCount,
    setPaginatedResults,
    filteredMemoResults,
    setTotalCount,
    setFetchedCount,
    handleInsightCitationClick
  };
};

export default useDocumentsViewFunctions;
