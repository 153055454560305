const styles = {
  container: {
    display: 'flex',
    width: 350,
    pb: 2,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: 3,
    border: '1px solid',
    borderColor: 'gray.300',
    backgroundColor: 'white.main',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)'
  },
  headingContainer: {
    display: 'flex',
    py: 1,
    px: 2,
    alignItems: 'center',
    gap: 1,
    backgroundColor: 'primary.0',
    width: '100%',
    borderRadius: '12px 12px 0 0'
  },
  labelChip: {
    fontSize: 10,
    height: 13,
    minWidth: 31,
    borderRadius: 1,
    fontWeight: 800,
    color: 'white.main',
    '& span': {
      px: 0.5
    }
  },
  metadataConatiner: {
    display: 'flex',
    py: 1.5,
    px: 2,
    alignItems: 'center',
    gap: 1,
    width: '100%'
  },
  tagText: {
    color: 'gray.700',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12
  },
  textConatiner: {
    display: 'flex',
    py: 0,
    px: 2,
    alignItems: 'center',
    gap: 1,
    width: '100%'
  },

  description: {
    color: 'gray.800',
    fontSize: 13,
    lineHeight: '22px',
    '&:hover': {
      color: 'primary.650',
      textDecoration: 'underline'
    }
  }
};

export default styles;
