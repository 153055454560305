import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { useContext, useState } from 'react';
import { Popover, Skeleton } from '@mui/material';

import ReportButton from '../../../../components/Report/ReportButton';

import DocumentCitationData from '../DocumentCitationData';
import useDocumentsViewFunctions from '../../hooks/useDocumentsViewFunctions';
import ResultsStore from '../../../../store/SearchResults';

const styles = {
  divider: {
    borderColor: 'gray.200'
  },
  loadingSkeleton: {
    bgcolor: 'primary.backgroundLight',
    height: 30
  }
};
const ResultContent = ({ content, chatId, citations, loading }: any) => {
  const { resultsState } = useContext(ResultsStore);
  const [hoverContentTargetElement, setHoverContentTargetElement] = useState<any>();
  const [hoverResultContent, setHoverResultContent] = useState<any>();
  const { findDocumentByResultId, handleInsightCitationClick } = useDocumentsViewFunctions();

  const handleHtmlClick = ({ target }: any) => {
    if (target.id?.includes('citation__')) {
      const citationId = parseInt(target.id.replace('citation__', ''), 10);
      const { resultId } = citations[citationId]; // remove an replace with below code after API integration
      const ariResult = findDocumentByResultId(resultId);
      handleInsightCitationClick(resultId, ariResult);
    }
  };

  const handleMouseMove = (event: any) => {
    event.stopPropagation();
    const { target } = event;
    if (target.id?.includes('citation__')) {
      const citationId = parseInt(target.id.replace('citation__', ''), 10);
      const { resultId } = citations[citationId];
      const matchedAriaDocumentResult = findDocumentByResultId(resultId);
      setHoverResultContent(matchedAriaDocumentResult);
      setHoverContentTargetElement(target);
    } else if (hoverResultContent || hoverContentTargetElement) {
      setHoverResultContent(null);
      setHoverContentTargetElement(null);
    }
  };

  const handleMouseLeave = (event: any) => {
    event.stopPropagation();
    setHoverResultContent(null);
    setHoverContentTargetElement(null);
  };

  return (
    <>
      <Box
        sx={{
          px: 4,
          py: 3,
          borderRadius: 4,
          backgroundColor: 'gray.50'
        }}>
        <Stack
          direction='column'
          divider={<Divider orientation='horizontal' sx={styles.divider} />}>
          <Typography fontSize={13} color='gray.700' pb={1}>
            Summary of top{' '}
            <Typography component='span' sx={{ color: 'gray.900', fontWeight: 900, fontSize: 13 }}>
              {resultsState?.documentResults?.results?.length > 10
                ? 10
                : resultsState?.documentResults?.results?.length}{' '}
              results
            </Typography>
          </Typography>
          {loading && !content ? (
            <Stack>
              {Array.from({ length: 5 }).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Stack key={index} mt={2}>
                  <Skeleton animation='wave' sx={styles.loadingSkeleton} />
                  <Skeleton animation='wave' width='60%' sx={styles.loadingSkeleton} />
                  <Skeleton animation='wave' width='30%' sx={styles.loadingSkeleton} />
                </Stack>
              ))}
            </Stack>
          ) : (
            <Typography
              py={1}
              fontSize={15}
              color='gray.800'
              minHeight={440}
              dangerouslySetInnerHTML={{ __html: content }}
              component={Box}
              onClick={handleHtmlClick}
              onMouseMove={handleMouseMove}
            />
          )}

          <Stack direction='row' alignItems='center' justifyContent='space-between' pt={1}>
            <ReportButton data={{}} addToReport={console.log} />
            <Box>Feedback</Box>
          </Stack>
        </Stack>
      </Box>
      <Popover
        onClose={handleMouseLeave}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: 'unset',
              color: 'unset',
              boxShadow: 'unset',
              padding: 0,
              margin: 0,
              border: 0
            },
            onMouseLeave: handleMouseLeave
          },
          root: {
            sx: {
              pointerEvents: 'none'
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorEl={hoverContentTargetElement}
        open={hoverContentTargetElement && hoverResultContent}>
        <DocumentCitationData resultDetails={hoverResultContent} />
      </Popover>
    </>
  );
};

export default React.memo(ResultContent);
