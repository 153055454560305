import { createSvgIcon } from '@mui/material';

const InfoIcon = createSvgIcon(
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_15297_21740)'>
      <path
        d='M7 14.5C5.61553 14.5 4.26216 14.0895 3.11101 13.3203C1.95987 12.5511 1.06266 11.4579 0.532846 10.1788C0.003033 8.8997 -0.13559 7.49224 0.134506 6.13437C0.404603 4.7765 1.07129 3.52922 2.05026 2.55026C3.02922 1.57129 4.2765 0.904603 5.63437 0.634506C6.99224 0.36441 8.3997 0.503033 9.67879 1.03285C10.9579 1.56266 12.0511 2.45987 12.8203 3.61101C13.5895 4.76216 14 6.11553 14 7.5C13.998 9.3559 13.2599 11.1352 11.9475 12.4475C10.6352 13.7599 8.8559 14.498 7 14.5ZM7 1.66667C5.84628 1.66667 4.71846 2.00879 3.75918 2.64976C2.79989 3.29074 2.05222 4.20178 1.61071 5.26768C1.16919 6.33359 1.05368 7.50647 1.27876 8.63803C1.50384 9.76959 2.05941 10.809 2.87521 11.6248C3.69102 12.4406 4.73042 12.9962 5.86198 13.2213C6.99353 13.4463 8.16642 13.3308 9.23232 12.8893C10.2982 12.4478 11.2093 11.7001 11.8502 10.7408C12.4912 9.78154 12.8333 8.65373 12.8333 7.5C12.8316 5.95343 12.2165 4.47068 11.1229 3.37709C10.0293 2.2835 8.54658 1.66837 7 1.66667Z'
        fill='currentColor'
      />
      <path
        d='M8.16658 11.5835H6.99992V7.50016H5.83325V6.3335H6.99992C7.30934 6.3335 7.60608 6.45641 7.82487 6.67521C8.04366 6.894 8.16658 7.19074 8.16658 7.50016V11.5835Z'
        fill='currentColor'
      />
      <path
        d='M7 5.1665C7.48325 5.1665 7.875 4.77475 7.875 4.2915C7.875 3.80825 7.48325 3.4165 7 3.4165C6.51675 3.4165 6.125 3.80825 6.125 4.2915C6.125 4.77475 6.51675 5.1665 7 5.1665Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_15297_21740'>
        <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'AdvanceSearchInfoIcon'
);
export default InfoIcon;
