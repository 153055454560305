import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import React from 'react';
import { AriaResponseSelected } from '../../../assets/svgs/Icons';
import { DOCUMENT_VIEW_SUBTABS } from '../constants';
import buttonStyles from '../styles/Buttons.styles';

const DocumentViewToggleButtons = ({ activeButton, onSelect }: any) => {
  return (
    <ToggleButtonGroup
      value={activeButton}
      onChange={onSelect}
      exclusive
      sx={buttonStyles.toggleGroup}>
      <ToggleButton
        value={DOCUMENT_VIEW_SUBTABS.RESULTS}
        aria-label={DOCUMENT_VIEW_SUBTABS.RESULTS}
        sx={buttonStyles.toggleGroupButton}>
        View All Results
      </ToggleButton>
      <ToggleButton
        value={DOCUMENT_VIEW_SUBTABS.RIA_INSIGHTS}
        aria-label={DOCUMENT_VIEW_SUBTABS.RIA_INSIGHTS}
        sx={{ ...buttonStyles.toggleGroupButton, px: 1.25 }}>
        <AriaResponseSelected sx={{ fontSize: 16 }} />
        <Typography component='span' fontWeight={700} lineHeight='normal'>
          RIA Insights
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default React.memo(DocumentViewToggleButtons);
