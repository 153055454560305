const styles = {
  title: {
    color: 'gray.900',
    fontWeight: 700,
    textTransform: 'capitalize',
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 300
  },
  chatRiaBtn: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'capitalize',
    color: 'blue.backgroundLight',
    py: 1,
    px: 0
  },
  sendIcon: { color: 'red', fontSize: 12 },
  tagText: {
    color: 'gray.700',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12
  },
  flag: { color: 'gray.500', display: 'flex' }
};

export default styles;
