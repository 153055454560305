const styles = {
  titleText: {
    color: 'gray.dark',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5
  },
  infoIcon: {
    fontSize: 12,
    color: 'grey.700'
  }
};

export default styles;
